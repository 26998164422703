import { html, TemplateResult } from 'lit-html';

import { heroImageSelector } from '../../constants';
import heroImageContent from '../heroImageContent/heroImageContent';
import handleLinkRedirect from '../../services/handleLinkRedirect';
import { BackgroundType, HeroImageProps } from '../../types/heroImageProps';

import {
    heroImageContainerStyle,
    heroVideoContainerStyle,
    heroImageFlexContainer,
    heroVideoStyle,
    innerContentContainerStyles,
    outerContentContainerStyles,
    videoContentStyle,
} from './styles';

const heroImage = (props: HeroImageProps): TemplateResult => {
    const {
        _: { id },
        backgroundType,
        videoUrl,
    } = props;

    const contentBody = html`
        <div class="${innerContentContainerStyles(props)}">${heroImageContent(props)}</div>
    `;
    const videoContentBody = html`
        <video
            class="${heroVideoStyle}"
            src="${videoUrl || ''}"
            type="video/mp4"
            autoplay
            loop
            data-test-id="hero-image-video"
        ></video>
        <div
            class="
                ${videoContentStyle(props)}
                ${innerContentContainerStyles(props)}"
        >
            ${heroImageContent(props)}
        </div>
    `;

    return html`
        <div class="${heroImageSelector(id)}">
            <div class="${heroImageFlexContainer()}">
                <div
                    class="${backgroundType === BackgroundType.VIDEO
                        ? heroVideoContainerStyle(props)
                        : heroImageContainerStyle(props)}"
                    @click="${(event: MouseEvent): void => handleLinkRedirect(event, props)}"
                >
                    ${backgroundType === BackgroundType.VIDEO ? videoContentBody : contentBody}
                </div>

                <div class="${outerContentContainerStyles(props)}">${heroImageContent(props)}</div>
            </div>
        </div>
    `;
};

export default heroImage;
